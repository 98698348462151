export const resolveImageName = (name, brand, colors) => {
    if(typeof name === 'string' && typeof brand === 'string' && typeof colors === 'object') {
        if(brand === 'Leviton') {
            return resolveImageNameLeviton(name);
        } else if(brand === 'Jach') {
            return resolveImageNameJach(name);
        } else if(brand === 'Innlite') {
            return resolveImageNameInnlite(name);
        } else if(brand === 'Ceisa' ) {
            return resolveImageNameCeisa(name);
        }

        if(name === 'M4+M4-3A') {
            return 'm4m4-3a';
        }

        const indexOfBrackets = name.indexOf('[');
        const indexOfSlash = name.indexOf('/');
        let result = name;
        let resultArray = [];
        if(indexOfSlash === -1 && indexOfBrackets === -1) {
            if(colors.length === 1) {
                return result;
            }
        } else if (indexOfSlash > -1 && indexOfBrackets > -1) {
            const indexFinal = indexOfSlash > indexOfBrackets ? indexOfBrackets : indexOfSlash;
            result = result.substring(0, indexFinal);
        } else {
            const indexFinal = indexOfSlash > indexOfBrackets ? indexOfSlash : indexOfBrackets;
            result = result.substring(0, indexFinal);
        }

        if(colors.length > 1) {
            for(let i = 0; i < colors.length; i++) {
                switch(colors[i]) {
                    case 'Negro':
                        resultArray.push(`${result}-black`);
                        break;
                    case 'Blanco':
                        resultArray.push(`${result}-white`);
                        break;
                    case 'Satin':
                        resultArray.push(`${result}-satin`);
                        break;
                    case 'Antracita':
                        resultArray.push(`${result}-antracita`);
                        break;
                } 
            }
            return resultArray;
        } else {
            return result;
        }
    } else {
        console.log(`name: ${name}`);
        console.log(`brand: ${brand}`);
        console.log(`color: ${colors}`)
        console.log('Ingresaste los datos mal, imbecil');
        return null;
    }
};

const resolveImageNameLeviton = (name) => {
    let indexin = name.indexOf('-');
    if(indexin > -1) {
        let beautifulResult = (name.slice(0, indexin) + name.slice(indexin +1) + '-leviton').toLowerCase();
        return beautifulResult;
    }
    return (name + '-leviton').toLowerCase();
};

const resolveImageNameJach = (name) => {
    const indexin = name.indexOf('[');
    if(indexin === -1) {
        return name.toLowerCase();
    }

    const resultado = (name.slice(0, indexin)).toLowerCase();
    if(resultado === 'emc3') {
        return [resultado + '-black', resultado + '-white'];
    } else if(resultado === 'epr16') {return resultado;}

    if(resultado.includes('jdls')) {return 'jdls';}
    else if(resultado.includes('pnlr')) {return 'pnlr';}
};

const resolveImageNameInnlite = (name) => {
    if(name === 'ADE-609' || name === 'ADE-610') {return 'ade609610';}
    else if(name === 'ADO-001' || name === 'ADO-009' ) {return 'ado001009';}

    let indexin = name.indexOf('-');
    if(indexin > -1) {
        let beautifulResult = (name.slice(0, indexin) + name.slice(indexin +1)).toLowerCase();
        return beautifulResult.toLowerCase();
    }

    return null;
};

const resolveImageNameCeisa = (name) => {
    const indexOfBrackets = name.indexOf('[');
        const indexOfSlash = name.indexOf('/');
        let result = name;
        if(indexOfSlash === -1 && indexOfBrackets === -1) {
        } else if (indexOfSlash > -1 && indexOfBrackets > -1) {
            const indexFinal = indexOfSlash > indexOfBrackets ? indexOfBrackets : indexOfSlash;
            result = result.substring(0, indexFinal);
        } else {
            const indexFinal = indexOfSlash > indexOfBrackets ? indexOfSlash : indexOfBrackets;
            result = result.substring(0, indexFinal);
        }

        return result;
}