import { Row, Container, Col } from "react-bootstrap";
import { Link } from 'react-router-dom';

const Button = ({ title, woah, hi, redirection }) => {
    return (
        <Container>
            <Row className="justify-content-center my-3">
                <Col className="sm-12" align='center'>
                    <Link to={`/${redirection}`} className="btn btn-orange" style={{width: `${woah}em`, height: `${hi}em`}}>{title}</Link>
                </Col>
            </Row>
        </Container>
        
    );
}

export default Button;