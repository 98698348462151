import { rest } from 'msw';
import samples from './data/samples.json';

export const handlers = [
    rest.get('/random-samples', (req, res, ctx) => {
        const results = samples.filter(sample => sample.product_id.length <= 6);
        return res(ctx.status(202), ctx.json(results));
    }),
    rest.get('/iluminacion/:type', (req, res, ctx) => {
        const { type } = req.params;
        const results = samples.filter(sample => sample.pr_type === type);
        return res(ctx.status(202), ctx.json(results));
    }),
    rest.get('/iluminacion/:type/:subtype', (req, res, ctx) => {
        const { type, subtype } = req.params;
        const results = samples.filter(sample => sample.pr_subtype === subtype);
        return res(ctx.status(202), ctx.json(results));
    }),
    rest.get('/producto/:id', (req, res, ctx) => {
        const {id} = req.params;
        const results = samples.filter(sample => sample.id === id);
        return res(ctx.status(202), ctx.json(results));
        //return res(ctx.status(202), ctx.json(samples));
        //return res(ctx.status(202), ctx.json({message: `I am getting tired, bitches... ${id}`, lol: type}))
    }),
    rest.get('/api/test', (req, res, ctx) => {
        return res(ctx.status(202), ctx.json({message: 'Valiendo maiz'}));
    })
];