const cleanProductName = (name) => {
    const indexOfBrackets = name.indexOf('[');
        let result = name;
        if(indexOfBrackets !== -1) {
            result = result.substring(0, indexOfBrackets);
        }

        return result;
}

export default cleanProductName;