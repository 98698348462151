import { Container, Col, Row } from "react-bootstrap";

const FooterFtw = () => {
    return (
        <Container fluid className="bg-dark mt-5">
            <Row>
                <Col className="border py-2">
                    <div className="img-footer">
                        <span><img src='/images/vilImg/location.png' style={{width: '2%'}} alt='pin' /></span>
                        <span className="text-white" style={{fontFamily: "'Quicksand', sans-serif"}}>   Local 32, Plaza San Ángel, Periférico de la Juventud</span>
                    </div>
                </Col>
                <Col className="border py-2">
                    <div className="img-footer">
                        <img src='/images/vilImg/telephone.png' alt='tel' />
                        <span className="text-white" style={{fontFamily: "'Quicksand', sans-serif"}}>   01 614 426 2222</span>
                    </div>
                </Col>
                <Col className="border py-2">
                    <ul className="footer-ul">
                        <li>
                            <div className="img-footer">
                                <img src='/images/vilImg/email.png' alt='em' />
                                <span className="text-white" style={{fontFamily: "'Quicksand', sans-serif"}}>   luiscarlos@vilumisen.mx</span>
                            </div>
                        </li>
                        <li>
                            <div className="img-footer">
                                <img src='/images/vilImg/email.png' alt='em' />
                                <span className="text-white" style={{fontFamily: "'Quicksand', sans-serif"}}>   ventas1@vilumisen.mx</span>
                            </div>
                        </li>
                    </ul>
                </Col>
            </Row>
        </Container>
    );
};

export default FooterFtw;