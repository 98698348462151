import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, NavItem, Container, NavDropdown } from 'react-bootstrap';

export const NavBar = () => {
    return (
        <Navbar expand="lg" lg='12' className='pb-0 pt-3' style={{borderBottom: '2px solid gray'}}>
            <Container>
                <Navbar.Brand as={Link} to="/" className='mr-0 pr-0 pt-0 pb-0'><img style={{height: '15vh', maxWidth: '100%'}} src='/images/vilImg/LogotipoVilumisen.png' alt='logo' /></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className='m-auto pb-0' as='ul'>
                        <NavDropdown title={
                            <div>
                                <img className='img-navbar' src='/images/vilImg/focos.png' alt='focos' />
                                <p>Iluminación</p>
                            </div>
                            } id="main-dropdown" className='navbar-text navbar-content px-2 mx-2'>
                                <NavDropdown.Item as={Link} to='/productos/tipo/Iluminacion/Foco'>
                                    Focos
                                </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to='/productos/tipo/Iluminacion/Tira'>
                                    Tiras
                                </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to='/productos/tipo/Iluminacion/Manguera'>
                                    Mangueras
                                </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to='/productos/tipo/Iluminacion/Luminaria'>
                                    Luminarias
                                </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to='/productos/tipo/Iluminacion/Panel'>
                                    Panel
                                </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to='/productos/tipo/Iluminacion/Reflector'>
                                    Reflectores
                                </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to='/productos/tipo/Iluminacion/Empotrado'>
                                    Spots
                                </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to='/productos/tipo/Accesorios'>
                                    Accesorios
                                </NavDropdown.Item>
                        </NavDropdown>
                        <NavItem href='/' as='li' className='px-2 mx-1 pb-0 pt-2'>
                            <Nav.Link as={Link} className='navbar-content navbar-text' to='/productos/tipo/Revestimiento'>
                                <img className='img-navbar' src='/images/vilImg/apagador.png' alt='apagador' />
                                <p>Revestimiento</p>
                            </Nav.Link>
                        </NavItem>
                        <NavItem href='/' as='li' className='px-2 mx-2 pb-0 pt-2'>
                            <Nav.Link as={Link} className='navbar-content navbar-text' to='/productos/tipo/Electrico'>
                                <img className='img-navbar' src='/images/vilImg/electrico.png' alt='electrico' />
                                <p>Eléctrico</p>
                            </Nav.Link>
                        </NavItem>
                        <NavItem href='/' as='li' className='px-2 mx-2 pb-0 pt-2'>
                            <Nav.Link as={Link} className='navbar-content navbar-text' to='/productos/tipo/Ventilacion'>
                                <img className='img-navbar' src='/images/vilImg/ventilacion.png' alt='ventilador' />
                                <p>Ventilación</p>
                            </Nav.Link>
                        </NavItem>
                        <NavItem href='/' as='li' className='px-2 mx-2 pb-0 pt-2'>
                            <Nav.Link as={Link} className='navbar-content navbar-text' to='/showroom'>
                                <img className='img-navbar' src='/images/vilImg/showroom2.png' alt='show' />
                                <p>Showroom</p>
                            </Nav.Link>
                        </NavItem>
                        
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}