import { Container, Row, Col, Card, Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import { getMainPageProducts } from "../api";
import { resolveImageName } from "../service/retrieveImages";
import { Link } from 'react-router-dom';

const MainPageProducts = () => {
    const [ products, setProducts ] = useState();

    useEffect(() => {
        const getSamples = async () => {
            const data = await getMainPageProducts();
            setProducts(data);
        }

        getSamples();
    }, []);

    const changeImageExtension = (error, imageName) => {
        error.target.src = imageName + '.png';
    };
    
    return (
        <Container>
            <Row>
                {products && products.map((prod) => {
                    const imageName = resolveImageName(prod.product_id, prod.brand, prod.color);
                    return (
                        <Col key={prod.product_id} sm={3} className="my-2">
                            <Card className="h-100">
                                <Card.Body as={Link} to={`/productos/${prod.id}`} style={{textDecoration: 'none'}}>
                                    <Card.Img src={`/images/catalogo/${prod.brand.toLowerCase()}/${imageName}.jpg`} 
                                    variant='top' 
                                    onError={(error) => changeImageExtension(error, `/images/catalogo/${prod.brand.toLowerCase()}/${imageName}`)}
                                    className="object-fit-sm-scale"
                                    style={{objectFit: 'contain'}} />
                                    <Card.Text>
                                        {prod.description}
                                        {/*prod.color.map((co) => {
                                            <Form.Check type='radio' label={co} />
                                        })*/}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    );
                })}
                {/*JSON.stringify(products, null, 2)*/}
            </Row>
        </Container>
    );
};

export default MainPageProducts;