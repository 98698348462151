import './App.css';
import Root from './components/Root';
import { RouterProvider, createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import MainPage from './pages/MainPage';
import Brands from './pages/Brands';
import ErrorPage from './pages/ErrorPage';
import DetailsPage from './pages/DetailsPage';
import Search from './pages/Search';
import Showroom from './pages/Showroom';

const appRouter = createBrowserRouter(createRoutesFromElements(
  <Route path='/' element={ <Root /> } >
    <Route index element={ <MainPage /> } />
    <Route path='/marcas' element={ <Brands /> } />
    <Route path='/productos/:id' element={ <DetailsPage /> } />
    <Route path='/productos/tipo/:type' element={ <Search /> } />
    <Route path='/productos/tipo/:type/:subtype' element={ <Search /> } />
    <Route path='/showroom' element={ <Showroom /> } />
    <Route path='*' element={ <ErrorPage /> }/>
  </Route>
));

function App() {
  return (
    <RouterProvider router={appRouter} />
  );
}

export default App;
