import { Container, Row, Col, Card } from "react-bootstrap";
import ProductCard from "../components/Card";
import { useEffect, useState } from "react";
import { getProductsByType, getProductsBySubtype } from "../api";
import { resolveImageName } from "../service/retrieveImages";
import { useParams, Link } from "react-router-dom";
import cleanProductName from '../service/cleanProductName';

const Search = () => {
    const [ products, setProducts ] = useState();
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const {type} = useParams();
    const {subtype} = useParams();

    useEffect(() => {
        const getDetailedProducts = async () => {
            try {
                if(!subtype) {
                    const data = await getProductsByType(type);
                    setProducts(data);
                    setError(false);
                } else {
                    const data = await getProductsBySubtype(type, subtype);
                    setProducts(data);
                    setError(false);
                }
            } catch(e) {
                setError(true);
            }
            setLoading(false);
        }
        //onError={(error) => changeImageExtension(error, `/images/catalogo/${prod.brand}/${imageName}`)}

        getDetailedProducts();
    }, [type, subtype]);

    const changeImageExtension = (error, imageName) => {
        error.target.src = imageName + '.png';
    };
    
    return (
        <Container>
            <Row>
                {
                loading ? (
                    <h3>Loading...</h3>
                ) : error ? (
                    <h3>Error loading data...</h3>
                ) :
                products.map((prod) => {
                    const productName = cleanProductName(prod.product_id);
                    let imageName = resolveImageName(prod.product_id, prod.brand, prod.color);
                    if(typeof imageName === 'object') {
                        imageName = imageName[0];
                    }
                    return (
                        <Col key={prod.product_id} sm={3} className="my-2">
                            <Card className="h-100">
                                <Card.Body as={Link} to={`/productos/${prod.id}`} style={{textDecoration: 'none'}}>
                                    <Card.Img 
                                        alt={`/${prod.brand.toLowerCase()}/${imageName}`}
                                        src={`/images/catalogo/${prod.brand.toLowerCase()}/${imageName}.jpg`}
                                        variant='top'
                                        onError={(error) => changeImageExtension(error, `/images/catalogo/${prod.brand.toLowerCase()}/${imageName}`)}
                                        className="object-fit-sm-scale"
                                        style={{objectFit: 'contain'}}
                                    />
                                    <Card.Text>
                                        {prod.description}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    );
                })
                }
                {/*JSON.stringify(products, null, 2)*/}
            </Row>
        </Container>
    );
};

export default Search;