import MegaCarousel from "../components/MegaCarousel";
import Button from "../components/Button";
import { Container } from 'react-bootstrap';
import MainPageProducts from "../components/MainPageProducts";

const MainPage = () => {
    return (
        <Container>
            <MegaCarousel />
            <Button title="Conozca nuestras marcas" woah="15" hi="3" redirection="marcas" />
            <MainPageProducts />
        </Container>
    );
}

export default MainPage;