import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { getProductDetails } from '../api';
import { resolveImageName } from '../service/retrieveImages';
import ProductInfo from '../components/ProductInfo';

const DetailsPage = () => {
    const [data, setData] = useState();
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [imageName, setImageName] = useState('None');
    const {id} = useParams();

    const changeImageExtension = (error, name) => {
        error.target.src = name + '.png';
    };

    useEffect(() => {
        const getDetails = async () => {
            try {
                const details = await getProductDetails(id);
                setData(details);
                setError(false);
                setImageName(resolveImageName(details[0].product_id, details[0].brand, details[0].color));
            } catch (e) {
                setError(true);
            }
            setLoading(false);
            /*try {
                const details = await getTest();
                setData(details);
                console.log(details);
            } catch (e) {
                setError(true);
            }
            setLoading(false);*/
        };

        getDetails();
    }, [id]);

    const getImageName = (name) => {
        if(typeof name === 'object') {
            return name[0];
        }

        return name;
    }

    return (
        <Container>
            <Row className='my-3'>
                {//data[0].key
                 //
                 loading ? (
                    <h3>Loading...</h3>
                ) : error ? (
                    <h3>Error loading data...</h3>
                ) : (
                    <>
                        <Col>
                            <img alt='' style={{maxWidth: '50%', height: 'auto'}} src={`/images/catalogo/${data[0].brand.toLowerCase()}/${getImageName(imageName)}.jpg`} onError={(error) => changeImageExtension(error, `/images/catalogo/${data[0].brand.toLowerCase()}/${getImageName(imageName)}`)} />
                        </Col>
                        <Col>
                            <ProductInfo datos={data[0]} />
                        </Col>
                    </>
                )
                }
            </Row>
        </Container>
    );
}

export default DetailsPage;