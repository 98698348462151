import { NavBar } from './NavBar';
import FooterFtw from './Footer';
import { Outlet } from 'react-router-dom';

const Root = () => {
    return (
        <>
            <NavBar />
            <Outlet />
            <FooterFtw />
        </>
    );
}

export default Root;