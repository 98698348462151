import { Carousel, Container, Row, Col } from "react-bootstrap";

const MegaCarousel = () => {
    return (
        <Container>
            <Row>
                <Col>
                    <Carousel style={{background: 'black'}}>
                        <Carousel.Item className="img-carousel">
                            <img
                                className="d-block"
                                src="/images/carousel/pexels-atul-maurya-1042152.jpg"
                                alt="First slide"
                            />
                            <Carousel.Caption>
                                <h4 style={{fontFamily: "'Roboto', sans-serif"}}>Iluminación y Revestimiento</h4>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item className="img-carousel">
                            <img
                                className="d-block"
                                src="/images/carousel/pexels-vecislavas-popa-1571457.jpg"
                                alt="Second slide"
                            />
                            <Carousel.Caption>
                                <h4 style={{fontFamily: "'Roboto', sans-serif"}}>Residencial</h4>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item className="img-carousel">
                            <img
                                className="d-block"
                                src="/images/carousel/pexels-gul-isik-15860802.jpg"
                                alt="Third slide"
                            />
                            <Carousel.Caption>
                                <h4 style={{fontFamily: "'Roboto', sans-serif"}}>Comercial</h4>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item className="img-carousel">
                            <img
                                className="d-block"
                                src="/images/carousel/drew-beamer-a7nqsUzZMYo-unsplash.jpg"
                                alt="Fourth slide"
                            />
                            <Carousel.Caption>
                                <h4 style={{fontFamily: "'Roboto', sans-serif"}}>Industrial</h4>
                            </Carousel.Caption>
                        </Carousel.Item>
                    </Carousel>
                </Col>
            </Row>
        </Container>
    );
}

export default MegaCarousel;