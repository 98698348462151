import { Col, Row } from 'react-bootstrap';
import cleanProductName from '../service/cleanProductName';

const ProductInfo = ({datos}) => {
    return (
        <>
            <Row>
                {<h4>{datos.description}</h4>}
            </Row>
            <Row>
                <Col>
                {datos.color && <>Colores: </>}
            {datos.color && datos.color.map((c) => {
                let colorFinal = getColor(c);
                return (<button key={c} style={{backgroundColor: colorFinal}} className='btnStyle mx-1' title={c} ></button>);
            })}
            </Col>
            </Row>
            <Row>
                <Col>
            {datos.temperature && <>Tipo de Luz: </>}
            {datos.temperature && datos.temperature.map((temp, index) => {return getTemperature(temp, index)})
            }
            </Col>
            </Row>

            {datos.pr_type === 'Accesorios' && <Row>Tipo de Accesorio: {datos.pr_subtype}</Row>
            }
            {datos.amperage && <Row>
                Amperaje: {datos.amperage} A
            </Row>}
            {!datos.voltage ? <></> :
                datos.voltage.length > 1 ? <Row>Voltaje: {datos.voltage[0]} - {datos.voltage[1]} V</Row> : datos.voltage[0] === 0 ? <></> :<Row>Voltaje: {datos.voltage[0]} V</Row>
            }
            {!datos.power ? <></> : (datos.pr_subtype === 'Manguera' || datos.pr_subtype === 'Tira') ? <Row>Watts: {datos.power} W/m</Row> : <Row>Watts: {datos.power} W</Row>}
            {datos.base && <Row>Base: {datos.base}</Row>}
        </>
    );
}

const getColor = (color) => {
    switch(color) {
        case 'Negro':
            return 'black';
        case 'Blanco':
            return 'white';
        case 'Gris':
            return 'gray';
        //satin, antracita, verde
        case 'Satin':
            return '#808080';
        case 'Antracita':
            return '#293133';
        case 'Verde':
            return '#3B5534';
        case 'Ambar':
            return '#E49E43';
        default:
            break;
    }
}

const getTemperature = (temp, index) => {
    let colorFinal;
    let etiqueta;
    switch(temp) {
        case 1: 
            colorFinal = '#fc0d0d';
            etiqueta = 'RGBW';
            break;
        case 0: 
            etiqueta = 'Ambar';
            colorFinal = '#E49E43';
            break;
        case 3000:
            etiqueta = 'Cálida';
            colorFinal = '#f2b807';
            break;
        case 2700:
            etiqueta = 'Cálida';
            colorFinal = '#f2b807';
            break;
        case 4000:
            etiqueta = 'Neutra';
            colorFinal = '#fffff7';
            break;
        case 4100:
            etiqueta = 'Neutra';
            colorFinal = '#fffff7';
            break;
        case 6500:
            etiqueta = 'Fría';
            colorFinal = '#e0f7ff';
            break;
        default:
            break;
    }

    return <button key={index} style={{backgroundColor: colorFinal}} className='btnStyle mx-1' title={etiqueta}></button>;
}

export default ProductInfo;