export const getMainPageProducts = async () => {
    const response = await fetch('/random-samples', {method: 'GET'});
    const json = await response.json();
    return json;
}

export const getProductsByType = async (type) => {
    const url = `/iluminacion/${type}`;
    const response = await fetch(url, {method: 'GET', headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }});
    const json = await response.json();
    return json;
}

export const getProductsBySubtype = async (type, subtype) => {
    const url = `/iluminacion/${type}/${subtype}`;
    const response = await fetch(url, {method: 'GET',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }});
    const json = await response.json();
    return json;
}

export const getProductDetails = async (id) => {
  const url= `/producto/${id}`;
  const response = await fetch(url, {method: 'GET'});
  const json = await response.json();
  return json;
}

export const getTest = async () => {
    const url = '/api/test';
    const response =await fetch(url, {method: 'GET', headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }});
    const json = await response.json();
    return json;
}