import brands from '../mocks/data/brands.json';
import { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Brands = () => {
    useEffect(() => {
        if(brands) {console.log('Data successfully fetched')}
    }, [])

    return (
        <Container>
            <Row>
                <Col align='center'>
                    <h2>Conozca nuestros proveedores</h2>
                </Col>
            </Row>
            <Row>
                {brands && brands.map((brand) => {
                    return(
                        <Col align='center' className='align-self-center' key={brand.id}>
                            <Link to={brand.url}><img src={brand.name !== 'qop' ? `./images/logo/${brand.name}.png` : `./images/logo/${brand.name}.gif`} style={{maxHeight: '100%', width: '20vw'}} alt={brand.name} /></Link>
                        </Col>
                    )
                })}
            </Row>
        </Container>
    );
};

export default Brands;